*,
*::before,
*::after {
    box-sizing: border-box;
}

.card {
    height: 20rem;
    width: 15rem;
    margin-bottom: 3rem;
    background-color: #F2F2F2;
    border-radius: 1rem;
    text-align: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

h6 {
    margin-bottom: 3rem;
}

img {
    height: 10rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    object-fit: cover;
    margin-bottom: 1rem;
}

.card:hover {
    transform: scale(1.1);
    transition: transform .2s;
}

.cardsGroup {
	padding-top: 2rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    place-items: center;
}