@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@600&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

h3 {
	font-family: 'IBM Plex Mono', monospace;
	align-self: center;
	color: #070464;
	text-transform: uppercase;
}

h5 {
	font-family: 'Roboto', sans-serif;
	white-space: pre-wrap;
	color: #070464;
	line-height: 1.5;
}

h6.v2 {
	font-family: 'Roboto', sans-serif;
	white-space: pre-wrap;
	color: #070464;
	line-height: 1.5;
}

h6 {
	font-family: 'IBM Plex Mono', monospace;
	align-self: center;
	color: #070464;
	margin-top: 0.5rem;
}

h6.title {
	margin-bottom: 1rem;
}

body {
	background-color: rgba(var(--b3f,250,250,250),1);
	background-image: url("img/gradient.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size:auto;
}

.justify {
	text-align: justify;
}

.top-spacing-bg {
	margin-top: 3rem;
	margin-bottom: 3rem;
}

.font-size-sm {
	font-size: 1rem;
}