*,
*::before,
*::after {
    box-sizing: border-box;
}

.container {
	width: 75rem;
	align-self: center;
	text-align: center;
	margin: auto;
	margin-bottom: 4rem;
	margin-top: 4rem;
	padding: 2rem;

	background-color: #f9f9f9;
	border: 1px solid rgba(var(--b6a,219,219,219),1);
	border-radius: 15px;
}


.container.bg-padding {
	padding-left: 8%;
	padding-right: 8%;
}

.home:hover {
    transform: scale(1.05);
    transition: transform .2s;
	cursor: pointer;
}
