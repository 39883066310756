.arrow-btn {
    font-size: 1.2rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.arrowsGroup {
	padding-top: 3%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content:  center;
}