.btn {
    background-color: #080474;
    color: white;
    width: 6rem;
    border-radius: 0.5rem;
}

.btn:hover {
    color: white;
    transform: scale(1.15);
    transition: transform .2s;
}